<template>
  <div>
    <slot name="activator" :on="showDialog"></slot>
    <!-- <div href="javascript:;" @click="showDialog()"> -->
    <slot></slot>
    <!-- </div> -->
    <app-dialog v-model="dialog" :title="baabd_check_header.document_no_" :loading="loading" class="dialogdetail" max-width="1400">
      <template #toolbar>
        <action-button :data-detail="data_detail" @refresh-detail="refreshDetail()" @callback-delete="hideDialog"></action-button>
        <v-spacer></v-spacer>
        <app-document-status v-model="baabd_check_header.status" class="ml-1"></app-document-status>
        <app-document-status v-model="baabd_check_header.substatus" class="ml-1"></app-document-status>
      </template>

      <v-row>
        <v-col cols="12" md="9">
          <v-row>
            <v-col cols="12">
              <card-expansion title="General">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field label="Document No." v-model="baabd_check_header.document_no_" hide-details outlined dense class="mb-2" readonly></v-text-field>
                    <v-text-field label="Document Date" v-model="baabd_check_header.document_date" hide-details outlined dense class="mb-2" readonly type="date"></v-text-field>
                    <v-text-field label="Area Code" v-model="baabd_check_header.area_code" hide-details outlined dense class="mb-2" readonly></v-text-field>
                    <v-text-field label="Document Type" v-model="baabd_check_header.document_type" hide-details outlined dense class="mb-2" readonly></v-text-field>
                    <v-textarea label="Description" v-model="baabd_check_header.description" hide-details outlined dense class="mb-2" readonly rows="4"></v-textarea>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field label="Created By" v-model="baabd_check_header.created_by_username" hide-details outlined dense class="mb-2" readonly></v-text-field>
                    <v-text-field label="Created Date" v-model="baabd_check_header.created_date" hide-details outlined dense class="mb-2" readonly type="datetime-local"></v-text-field>
                    <v-text-field label="Status" v-model="baabd_check_header.status" hide-details outlined dense class="mb-2" readonly></v-text-field>
                    <v-text-field label="Last Updated" v-model="baabd_check_header.last_updated" hide-details outlined dense class="mb-2" readonly type="datetime-local"></v-text-field>
                    <v-text-field label="Closing Date" v-model="baabd_check_header.closing_date" hide-details outlined dense class="mb-2" readonly type="date"></v-text-field>
                  </v-col>
                </v-row>
                <div>
                  <app-button @click="redirect('Finance.BaabdCheck.Detail', { id: id })" title="Detail" mdi-icon="mdi-file-find" label="View Detail"></app-button>
                </div>
              </card-expansion>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <card-expansion :hide-title="true">
                <template #title>
                  <v-tabs v-model="tab">
                    <v-tab>Approval</v-tab>
                  </v-tabs>
                </template>

                <!-- Item -->
                <v-tabs-items v-model="tab" class="elevation-1">
                  <v-tab-item>
                    <v-data-table :items="baabd_check_approval" :headers="baabd_check_approval_columns">
                      <template v-slot:[`item.approved`]="{ item }">
                        <span v-if="item.approved == true">
                          <v-icon color="primary">mdi-check</v-icon>
                        </span>
                      </template>
                    </v-data-table>
                  </v-tab-item>
                </v-tabs-items>
              </card-expansion>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="3">
          <app-comment :document_id="parseInt(baabd_check_header.id)" :source_document="baabd_check_header.source_document" :document_no_="baabd_check_header.document_no_"></app-comment>
        </v-col>
      </v-row>
    </app-dialog>
  </div>
</template>

<script>
import ActionButton from "./ActionButton";

export default {
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  components: {
    ActionButton,
  },
  data() {
    return {
      loading: false,
      loading_delete: false,
      loading_send_approval: false,
      loading_reopen: false,
      loading_close_document: false,
      loading_approve: false,
      loading_cancel: false,
      loading_attachment: false,
      dialog: false,
      tab: null,
      modal1: false,
      data_detail: {},
      baabd_check_header: {},
      user: {},
      baabd_check_approval_columns: [
        { text: "Approval", value: "approval_label", sortable: false },
        { text: "User", value: "username", sortable: false },
        {
          text: "Approve Status",
          value: "approved",
          sortable: false,
          align: "center",
        },
        { text: "Approve Date", value: "approved_date", sortable: false },
      ],
      baabd_check_approval: [],
      fullscreen: false,
      selectedApprovalItemToogle: false,
    };
  },
  watch: {
    dialog(n, o) {
      if (o == true && n == false) {
        this.$emit("callback");
      }
    },
    // eslint-disable-next-line no-unused-vars
    selectedApprovalItemToogle(n, o) {
      this.baabd_check_item.forEach((item) => {
        if (item.original_item_approved === null || item.original_item_approved === true) {
          item.item_approved = n;
        }
      });
    },
  },
  computed: {
    totalPrice() {
      var total = 0;
      this.baabd_check_item.forEach((item) => {
        total = total + item.quantity * item.unit_price;
      });
      return total;
    },
    totalPriceFormatted() {
      var total = 0;
      this.baabd_check_item.forEach((item) => {
        total = total + item.quantity * item.unit_price;
      });
      return this.numberFormat(total);
    },
    enableDeleteBtn() {
      if (this.inArray(this.baabd_check_header.status, ["closed", "cancel"])) return false;

      if (this.baabd_check_header.created_by_username !== this.$store.state.loginInfo.username) return false;

      if (this.modulePermission("baabdcheck", "delete") && !this.inArray(this.baabd_check_header.status, ["approved"]) && this.inArray(this.baabd_check_header.status, ["open"])) {
        return true;
      }
      return false;
    },
    enableReopenBtn() {
      if (this.inArray(this.baabd_check_header.status, ["closed", "cancel"])) return false;

      if (this.baabd_check_header.created_by_username !== this.$store.state.loginInfo.username) return false;

      if (this.modulePermission("baabdcheck", "reopen") && !this.inArray(this.baabd_check_header.status, ["approved"]) && !this.inArray(this.baabd_check_header.status, ["open"])) {
        return true;
      }
      return false;
    },
    enableCancelBtn() {
      if (this.inArray(this.baabd_check_header.status, ["closed", "cancel"])) return false;

      if (this.modulePermission("baabdcheck", "cancel") && !this.inArray(this.baabd_check_header.status, ["approved"]) && !this.inArray(this.baabd_check_header.status, ["cancel"])) {
        if (this.baabd_check_header.created_by_username === this.$store.state.loginInfo.username) {
          return true;
        }
      }
      return false;
    },
    enableSendApprovalBtn() {
      if (this.inArray(this.baabd_check_header.status, ["closed", "cancel"])) return false;

      if (this.modulePermission("baabdcheck", "send-approval") && this.baabd_check_header.status == "open") {
        return true;
      }
      return false;
    },
    enableApproveBtn() {
      if (this.inArray(this.baabd_check_header.status, ["closed", "cancel"])) return false;

      if (this.modulePermission("baabdcheck", "approve") && this.baabd_check_header.status == "waiting-approval") {
        let show = false;
        let l_uname = this.$store.state.loginInfo.username;
        for (var i = 0; i < this.baabd_check_approval.length; i++) {
          let apprv = this.baabd_check_approval[i].approved;
          let uname = this.baabd_check_approval[i].username;

          if (apprv == 0) {
            if (uname == l_uname) {
              show = true;
              return true;
            }
          }
        }

        return show;
      }
      return false;
    },
    enableEditBtn() {
      if (this.inArray(this.baabd_check_header.status, ["closed", "cancel"])) return false;

      if (this.modulePermission("baabdcheck", "modify") && this.baabd_check_header.status == "open") {
        return true;
      }
      return false;
    },
    enableEditAccountingBtn() {
      if (this.inArray(this.baabd_check_header.status, ["closed", "cancel"])) return false;

      if ((this.modulePermission("baabdcheck", "update-book-value") || this.modulePermission("baabdcheck", "update-acquisition-value")) && this.inArray(this.baabd_check_header.status, ["open", "waiting-approval", "approved"])) {
        return true;
      }
      return false;
    },
    enableCloseBtn() {
      if (this.inArray(this.baabd_check_header.status, ["closed", "cancel"])) return false;

      if (this.modulePermission("baabdcheck", "close") && this.baabd_check_header.status == "approved") {
        return true;
      }
      return false;
    },
    isFullscreen() {
      if (this.isMobile) return true;
      if (this.fullscreen) return true;

      return false;
    },
    isShowSelectedApprovalItem() {
      if (this.modulePermission("baabdcheck", "approve")) {
        if (this.inArray(this.baabd_check_header.status, ["waiting-approval"])) return true;
      }

      return false;
    },
  },
  methods: {
    refreshDetail() {
      this.getDetail(true);
    },

    showDialog() {
      this.dialog = true;
      this.getDetail(true);
    },

    hideDialog() {
      this.dialog = false;
    },

    async getDetail(refresh = false) {
      this.loading = true;

      this.baabd_check_header = {};
      this.baabd_check_approval = [];
      this.user = {};

      if (refresh == true) this.AxiosStorageRemove("GET", "baabdcheck/detail");

      this.showLoadingOverlay(true);

      var config = {
        params: {
          id: this.id,
        },
        cacheConfig: true,
      };
      await this.$axios
        .get("baabdcheck/detail", config)
        .then((res) => {
          this.data_detail = res.data.data;
          this.baabd_check_header = res.data.data.baabd_check_header;
          this.baabd_check_approval = res.data.data.baabd_check_approval;
          this.user = res.data.data.user;

          this.showLoadingOverlay(false);

          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },
  },
};
</script>

<style scoped>
.vcard-text-dd-hh {
  max-height: 550px;
  overflow-y: auto;
}

div.cpre {
  max-height: 260px;
  overflow-y: auto;
}

.ipre {
  white-space: pre-wrap;
  /* css-3 */
  white-space: -moz-pre-wrap;
  /* Mozilla, since 1999 */
  white-space: -pre-wrap;
  /* Opera 4-6 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  word-wrap: break-word;
  /* Internet Explorer 5.5+ */
}
</style>
