<template>
  <div>
    <v-card flat elevation="0" class="">
      <app-data-table ref="refAppDataTable" :uri="datatable.uri" :headers="datatable.headers">
        <template v-slot:document_no_="{ item }">
          <detail-dialog :id="parseInt(item.id)" @callback="getData(true)">

            <template v-slot:activator="{ on }">
              <a href="javascript:;" v-on:click="on" style="text-wrap: nowrap;"><v-icon small>mdi-file</v-icon> {{ item.document_no_ }}</a>
            </template>

          </detail-dialog>
        </template>

        <template v-slot:status="{ item }">
          <app-document-status v-model="item.status"></app-document-status>
        </template>
      </app-data-table>
    </v-card>
  </div>
</template>

<script>
import DetailDialog from "./DetailDialog.vue";
export default {
  components: { DetailDialog },
  data() {
    return {
      loading: null,

      datatable: {
        uri: "baabdcheck/has-been-approved",
        headers: [
          { text: "No.", value: "document_no_", class: "text-no-wrap" },
          { text: "Document Date", value: "document_date", class: "text-no-wrap" },
          { text: "Source Document", value: "source_document", class: "text-no-wrap" },
          { text: "Document Type", value: "document_type", class: "text-no-wrap" },
          { text: "Area Code", value: "area_code", class: "text-no-wrap" },
          { text: "Target Date", value: "target_date", class: "text-no-wrap" },
          { text: "Status", value: "status", class: "text-no-wrap", align: "center" },
          { text: "Created By", value: "created_by_username", class: "text-no-wrap" },
          { text: "Created Date", value: "created_date", class: "text-no-wrap" },
          { text: "Last Update", value: "last_updated", class: "text-no-wrap" },
          { text: "Closing Date", value: "closing_date", class: "text-no-wrap" },
        ],
      },


    };
  },
  watch: {
    datatable: {
      handler() {
        this.$emit("total-data", this.datatable.totalRows);
      },
      deep: true,
    }
  },
  methods: {
    refreshData() {
      this.$refs.refAppDataTable.refresh();
    },
  },
};
</script>
