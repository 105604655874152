<template>
  <div>
    <v-card flat elevation="0" class="">
      <v-card-title>My List
        <v-spacer></v-spacer>
        <v-chip @click="filterByStatusToogle('all')" :color="filterStatusColorAll" class="me-1">All</v-chip>
        <v-chip @click="filterByStatusToogle('open')" :color="filterStatusColorOpen" class="me-1">Open</v-chip>
        <v-chip @click="filterByStatusToogle('waiting-approval')" :color="filterStatusColorWaitingApproval" class="me-1">Waiting Approval</v-chip>
        <v-chip @click="filterByStatusToogle('approved')" :color="filterStatusColorApproved" class="me-1">Approved</v-chip>
      </v-card-title>
      <v-divider></v-divider>
      <app-data-table ref="refAppDataTable" :uri="datatable.uri" :headers="datatable.headers" show-filter :filter-data="filterForm">
        <template v-slot:document_no_="{ item }">
          <detail-dialog :id="parseInt(item.id)" @callback="refreshData(true)">

            <template v-slot:activator="{ on }">
              <a href="javascript:;" v-on:click="on" style="text-wrap: nowrap;"><v-icon small>mdi-file</v-icon> {{ item.document_no_ }}</a>
            </template>

          </detail-dialog>
        </template>

        <template v-slot:status="{ item }">
          <app-document-status v-model="item.status"></app-document-status>
        </template>

        <template #filter-body>
          <v-autocomplete v-model="filterForm.status" label="Status" :items="filterData.status" class="mb-2" hide-details multiple deletable-chips chips clear-icon clearable small-chips hide-selected outlined></v-autocomplete>
          <v-autocomplete v-model="filterForm.area_code" label="Area" :items="filterData.area_code" item-text="area_code" item-value="area_code" class="mb-2" hide-details multiple chips deletable-chips clear-icon clearable small-chips hide-selected outlined></v-autocomplete>
        </template>
      </app-data-table>
    </v-card>
  </div>
</template>

<script>
import DetailDialog from "./DetailDialog.vue";
export default {
  components: { DetailDialog },
  data() {
    return {
      loading: null,

      datatable: {
        uri: "baabdcheck/data",
        headers: [
          { text: "No.", value: "document_no_", class: "text-no-wrap" },
          { text: "Document Date", value: "document_date", class: "text-no-wrap" },
          { text: "Source Document", value: "source_document", class: "text-no-wrap" },
          { text: "Document Type", value: "document_type", class: "text-no-wrap" },
          { text: "Area Code", value: "area_code", class: "text-no-wrap" },
          { text: "Cust. No.", value: "customer_no_", class: "text-no-wrap" },
          { text: "Cust. Name", value: "customer_name", class: "text-no-wrap" },
          { text: "Campaign No.", value: "customer_no_", class: "text-no-wrap" },
          { text: "Campaign Name", value: "customer_name", class: "text-no-wrap" },
          { text: "Status", value: "status", class: "text-no-wrap", align: "center" },
          { text: "Created By", value: "created_by_username", class: "text-no-wrap" },
          { text: "Created Date", value: "created_date", class: "text-no-wrap" },
          { text: "Last Update", value: "last_updated", class: "text-no-wrap" },
        ],
      },


      filterForm: {
        status: [],
        area_code: [],
      },
      filterData: {
        status: [
          'open',
          'waiting-approval',
          'approved',
          'cancel',
          'closed',
        ],
        area_code: [],
      },
      filterStatusColorAll: "",
      filterStatusColorOpen: "",
      filterStatusColorWaitingApproval: "",
      filterStatusColorApproved: "",
    };
  },
  watch: {
    filterForm: {
      handler(val) {
        this.filterStatusColorAll = (val.status == null && this.filterForm.status == []) ? 'primary' : "";
        this.filterStatusColorOpen = (val.status !== null && this.inArray('open', val.status)) ? 'primary' : "";
        this.filterStatusColorWaitingApproval = (val.status !== null && this.inArray('waiting-approval', val.status)) ? 'primary' : "";
        this.filterStatusColorApproved = (val.status !== null && this.inArray('approved', val.status)) ? 'primary' : "";
      },
      deep: true,
    }

  },
  methods: {
    refreshData() {
      this.$refs.refAppDataTable.refresh();
    },
    filterByStatusToogle(status) {

      if (this.filterForm.status === null) {
        this.filterForm.status = [];
      }

      if (status == 'all') {
        this.filterForm.status = [];
      } else {
        if (this.filterForm.status.length > 0 && this.inArray(status, this.filterForm.status)) {
          let i = null;
          this.filterForm.status.forEach((item) => {
            if (item == status) {
              i = this.filterForm.status.indexOf(item);
            }
          })

          if (i != null)
            this.filterForm.status.splice(i, 1);
        } else {
          this.filterForm.status.push(status);
        }
      }

      this.refreshData();
    },
  },
  mounted() { },
};
</script>
