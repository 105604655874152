<template>
  <div>
    <v-dialog v-model="dialog" :overlay="false" max-width="500px" transition="dialog-transition" persistent>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on">
          Export
        </v-btn>
      </template>


      <v-card>
        <v-card-title primary-title>
          Export
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <div style="min-height: 360px;" class="my-2">
            <v-autocomplete :disabled="loading" multiple :items="statuses" v-model="selected_status" label="Select Status" dense outlined></v-autocomplete>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-btn @click="getExportResult" color="primary" :loading="loading">Export</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="dialog = !dialog">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: "",
      loading: false,
      selected_status: [],
      statuses: ["open", "waiting-approval", "approved", "cancel", "closed"],
    };
  },

  methods: {
    async getExportResult(refresh){
      if (refresh) this.AxiosStorageRemove("POST", "baabdcheck/export");

      this.loading = true;
      var formData = new FormData();
      formData.append("status", this.selected_status);

      this.$axios
        .post("baabdcheck/export", formData)
        .then((res) => {
          var resData = res.data;
          if (resData.status == "success") {
            this.goToExternalURL(resData.data.url);

          } else {
            this.showAlert(resData.status, resData.message);
          }
          this.loading = false;
        })
        .catch((error) => {
          this.axiosErrorHandler(error);
          this.loading = false;
        });
    }
  }
}
</script>